<template>
  <Modal addClass="modal-livetv modal-concurrency">
    <div class="modal-header">
      <h5 class="modal-title" :title="asset.title">{{ asset.title }}</h5>

      <div class="modal-metadata">
        <span
          class="channel-logo"
          :title="asset.channel.name"
          v-if="asset.channel"
        >
          <img
            :src="asset.channel.logo"
            class="img-logo"
            :alt="asset.channel.name"
            width="40"
            height="40"
          />
        </span>
        <span v-if="live" class="tag">en vivo</span>

        <span class="certificate-code" :title="asset.certificate">{{
          asset.certificateCode
        }}</span>
        <span class="total-time">{{ timeText }}</span>
      </div>
    </div>
    <div>
      <img src="assets/images/4-placa-pack-contratado-limite-devices.png" />
    </div>
    <!-- <main role="main" class="wrapper container-fluid my-content">
        <div class="content empty-content">
          <div class="section-header">
            <img src="assets/images/icon-pack-limite.svg" alt="">
          </div>
          <div class="section-data">
            <h4>TENÉS CONTRATADO EL PACK, PERO LLEGASTE AL <br>LÍMITE DE DISPOSITIVOS DISPONIBLES</h4>
            <p>Entrá a Sucursal Virtual para gestionar el acceso al contenido: <br>https://www.telecentro.com.ar/sucursal-virtual/</p>
          </div>
        </div>
      </main> -->
  </Modal>
</template>

<script>
export default {
  name: "modalConcurrency",
  components: {
    ProgressBarLive: () => import("@/components/Progress-bar-live.vue"),
    Modal: () => import("@/components/Modal.vue"),
  },
  inject: ["modalConfirmShow"],
  props: {
    live: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      asset: {},
    };
  },
  computed: {
    timeText() {
      const asset = this.asset;
      let text = "";

      if (asset.fechaInicio) {
        text += asset.fechaInicio;
      }
      if (asset.startHour && asset.endHour) {
        text += " de " + asset.startHour + " a " + asset.endHour + " hs.";
      }
      return text;
    },
  },
  mounted() {
    const self = this;
    this.$bus.$on("modal-concurrency:show", this.show);
  },

  beforeDestroy() {
    this.$bus.$off("modal-concurrency:show", this.show);
  },
  methods: {
    show(data) {
      const self = this;
      const asset = data;
      showModal(".modal-concurrency", function () {
        self.showDetail(asset);
      });
    },
    showDetail(asset) {
      const self = this;
      self.asset = asset;
      self.asset.title = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.title
      );
      self.asset.synopsis = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.synopsis
      );
    },
  },
};
</script>
